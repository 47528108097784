"use client";
import Link from "next/link";
import Image from "next/image";
import { FaYoutube, FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { prodServerUrl } from "../../app/utils/globals";
import ScrollToTop from "../common/ScrollToTop/ScrollToTop";

export default () => {
  const [disable, setDisable] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const submitHandler = async ({ email }) => {
    try {
      if (email) {
        setDisable(true);
      }

      const data = await axios.post(`${prodServerUrl}/subscribe-us`, {
        email: email,
      });

      if (data.status === 200) {
        toast.success(
          "We have received your query. Your will be contacted soon."
        );
        setValue("email", "");

        setDisable(false);
      }
    } catch (e) {
      toast.error(e.message);
      setDisable(false);
    }
  };

  const footerNavs = [
    {
      label: "Quick Links",
      items: [
        {
          href: "/courses",
          name: "Courses",
        },
        {
          href: "/career",
          name: "We Are Hiring",
        },
        {
          href: "/about",
          name: "About",
        },
        {
          href: "/contact",
          name: "Contact Us",
        },
        {
          href: "/terms",
          name: "Terms and Conditions",
        },
        {
          href: "/policy",
          name: "Privacy Policy",
        },
        {
          href: "/refund",
          name: "Refund and Returns Policy",
        },
      ],
    },
  ];

  return (
    <footer className="text-[#cdc4c4] bg-[#0c2e36] px-4 py-5 mx-auto md:px-8">
      <div className="mx-auto px-4 py-2 container">
        <div className="gap-6 justify-between md:flex">
          <div className="flex-1">
            <div className="max-w-xs">
              <Link href="/">
                <Image
                  src="/assets/logo/logo_transparent.png"
                  width={200}
                  height={200}
                  alt="vidyastu logo"
                />
              </Link>
              <p className="leading-relaxed mt-2 text-xs text-[#cdc4c4]">
                Online learning platform empowering minds, offering interactive
                courses from data science to web dev, boosting skills for career
                success.
              </p>
            </div>
            <div className="max-w-xs my-3">
              <p className="leading-relaxed mt-2 text-[20px] uppercase text-[#cdc4c4]">
                Download Our App
              </p>
              <Link href="https://play.google.com/store/apps/details?id=com.sisyphusinfotech.vidyastu">
                <Image
                  src="/images/google-play-store.svg"
                  width={200}
                  height={200}
                  alt="vidyastu logo"
                />
              </Link>
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
              <label className="block pt-4 pb-2" htmlFor="email">
                Stay Upto Date
              </label>
              <div className="max-w-sm flex items-center border rounded-md p-1">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="w-full p-2.5 outline-none"
                />
                <button className="p-2.5 rounded-md text-white bg-colorHeading outline-none shadow-md focus:shadow-none sm:px-5">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
          <div className="flex-1 mt-10 space-y-6 items-center justify-between sm:flex md:space-y-0 md:mt-0">
            {footerNavs.map((item, idx) => (
              <ul className="space-y-4 border-red-300 -mt-16" key={idx}>
                <p className="text-xl">{item.label}</p>
                {item.items.map((el, idx) => (
                  <li key={idx} className="text-xs">
                    <Link href={el.href}>{el.name}</Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>

          <div className="flex-1 my-3">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.730398522733!2d88.41310387511618!3d22.626540679453377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89e10d12097a7%3A0xa74122e5f4a78073!2s74%2C%20Rastraguru%20Ave%2C%20Clive%20House%2C%20Ramgarh%2C%20Kolkata%2C%20West%20Bengal%20700028!5e0!3m2!1sen!2sin!4v1719408263747!5m2!1sen!2sin" 
              className="w-80 md:w-96 md:h-44 rounded-md"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Vidyastu Location Map"
            ></iframe>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-8 py-6 border-t items-center justify-between sm:flex">
          <div className="mt-4 sm:mt-0">
            &copy; 2024 Vidyastu All Rights Reserved. <br /><span className="text-xs">Designed & Developed by <Link href="https://sisyphusinfotech.com/" target="_blank" className="">Sisyphus Infotech</Link></span>
          </div>
          <div className="mt-4 sm:mt-0">
            <ScrollToTop />
          </div>
          <div className="mt-6 sm:mt-0">
            <ul className="flex items-center space-x-4">
              <li className="w-10 h-10 border rounded-full flex items-center justify-center hover:bg-white">
                <Link
                  href="https://www.youtube.com/@vidyastu1787"
                  target="_blank"
                  aria-label="Youtube"
                >
                  <FaYoutube className="text-xl text-red-600" />
                </Link>
              </li>
              <li className="w-10 h-10 border rounded-full flex items-center justify-center hover:bg-white">
                <Link href="https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20your%20website.%22&type=phone_number&app_absent=0" aria-level={'Whatsapp'} >
                  <IoLogoWhatsapp className="text-colorHeading text-xl" />
                </Link>
              </li>
              <li className="w-10 h-10 border rounded-full flex items-center justify-center hover:bg-white">
                <Link
                  href="https://www.facebook.com/vidyastueducation"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebook className="text-xl text-blue-700" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

"use client";

import { Disclosure } from "@headlessui/react";
import { AiOutlineMenuFold, AiOutlineCloseCircle } from "react-icons/ai";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Premium Courses", href: "/premium-courses/", current: false },
  { name: "Resourses", href: "/free-courses/", current: false },
  // { name: "Event", href: "/events/", current: false },
  { name: "About", href: "/about/", current: false },
  { name: "Blog", href: "/blog/", current: false },
  { name: "We Are Hiring", href: "/career/", current: false },
  { name: "Contact Us", href: "/contact/", current: false },
];

const Navbar = () => {
  const routerPath = usePathname(false);

  return (
    <div className="bg-white drop-shadow-2xl sticky top-0 z-[10000]">
      <Disclosure
        as="nav"
        className="mx-auto py-4 xm:px-2 md:px-4 lg:px-4 container"
      >
        {({ open }) => (
          <>
            <div className="mb-4 pb-2 flex flex-col lg:flex-row justify-between items-center border-b border-zinc-200">
              <div className="flex flex-row justify-center items-center space-x-4">
                <p className="flex flex-row items-center text-xs gap-2 mr-2">
                  <MdOutlineMailOutline size={10} />
                  Email: official@vidyastu.in
                </p>
                {" | "}
                <p className="flex flex-row items-center text-xs gap-2">
                  <FaPhoneAlt size={10} />
                  Call: 
                  <Link href="tel:+919051977167">+91-9051977167</Link>
                </p>
              </div>
              <div className="flex flex-row justify-center items-center">
                <p className="text-xs">Follow Us:</p>
                <div className="flex flex-row items-center">
                  <Link href="https://www.facebook.com/vidyastueducation" target="_blank" className="mx-2">
                    <FaFacebookSquare
                      size={20}
                      className="text-blue-600"
                    />
                  </Link>
                  <Link href="https://www.youtube.com/@vidyastu1787" target="_blank" className="mx-2">
                    <FaYoutube size={20} className="text-red-500" />
                  </Link>
                  <Link
                    href="https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20your%20website.%22&type=phone_number&app_absent=0"
                    aria-level={"Whatsapp"}
                    target="_blank"
                    className="mx-2"
                  >
                    <IoLogoWhatsapp size={20} className="text-green-500" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex md:flex-row md:gap-y-7 items-center justify-between">
                <Link
                  href="/"
                  className="md:flex md:flex-col md:items-center md:justify-start"
                >
                  {/* <Image
                    src="/assets/logo/logo_transparent.png"
                    width={200}
                    height={150}
                    alt="vidyastu logo"
                    className="ml-2 md:ml-0"
                  /> */}
                  <h5 className="ml-2 md:ml-0 uppercase tracking-widest text-blue-600 font-bold text-3xl">vidya<span className="text-yellow">s</span>tu</h5>
                  <p className="mt-4 text-xs tracking-widest uppercase text-blue-800 font-semibold ml-2 md:ml-0">
                    Your Online Finishing School
                  </p>
                </Link>
                {/* mobile menu */}
                <div className="block items-center lg:hidden">
                  {/* mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-black hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open Main Menu</span>
                    {open ? (
                      <AiOutlineCloseCircle
                        size={30}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <AiOutlineMenuFold
                        size={30}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                {/* desktop menu items */}
                <div className="hidden lg:flex flex-row space-x-3 items-center justify-between">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className={`${
                        routerPath === item.href
                          ? "text-blue-600 border-b-2 border-t-2 border-blue-600"
                          : "text-black"
                      } px-3 py-2 rounded text-xs font-medium hover:bg-blue-200`}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <Link href={"https://vidyastu.online/"} target="_blank">
                    <button className="text-xs font-medium w-fit border border-blue-500 text-blue-500 p-2 rounded">
                      Purchase Course
                    </button>
                  </Link>
                  <Link href="https://play.google.com/store/apps/details?id=com.sisyphusinfotech.vidyastu">
                    <p className="text-white bg-black p-2 w-fit gap-2 text-xs justify-center flex items-center rounded">
                      Download Our App
                      <Image
                        src="/images/google-play-icon.svg"
                        width={15}
                        height={15}
                        alt="Google Play Store Icon"
                      />
                    </p>
                  </Link>
                </div>
              </div>
              {/* mobile menu items  */}
              <Disclosure.Panel className="p-6 space-y-2 lg:hidden">
                {navigation.map((item) => (
                  <Link href={item.href} key={item.name}>
                    <Disclosure.Button
                      as="a"
                      aria-current={item.current ? "page" : undefined}
                      className={`${
                        routerPath === item.href
                          ? "text-blue-600"
                          : "text-black hover:bg-blue-200"
                      } block px-3 py-2 rounded-lg text-base font-medium`}
                    >
                      {item.name}
                    </Disclosure.Button>
                  </Link>
                ))}
                <Link href={"https://vidyastu.online/"} target="_blank">
                  <button className="text-md font-medium w-full bg-blue-600 py-2 rounded text-white">
                    Purchase Course
                  </button>
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.sisyphusinfotech.vidyastu">
                  <p className=" text-white bg-black py-2 w-full mt-3 gap-2 text-sm justify-center flex items-center rounded">
                    Download Our App
                    <Image
                      src="/images/google-play-icon.svg"
                      width={15}
                      height={15}
                      alt="Google Play Store Icon"
                    />
                  </p>
                </Link>
              </Disclosure.Panel>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navbar;
